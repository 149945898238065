<template>
  <div id="award-type-list">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>

        <v-spacer></v-spacer>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              medium
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              shaped
              class="font-size-h6 mr-3 my-3 white--text"
              >&nbsp; Refresh &nbsp;
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle> -->
      <v-card-text>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="confirmAlert"
        >
          <v-container class="py-0">
            <v-row wrap>
              <v-col cols="12" sm="6" md="6" lg="4">
                <h6><span class="text-danger">*</span> Paid Date</h6>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="PaidDate"
                      label="Paid Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="PaidDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Membership Id</h6>
                <v-text-field
                  v-model="MembershipId"
                  placeholder="Enter Membership Id"
                  :rules="MembershipIdRules"
                  required
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="6" align="center">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  elevation="30"
                  shaped
                  tile
                  small
                  color="primary"
                  class="font-size-h6 px-4 py-4 mt-6 white--text"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="rows.MembershipId > 0">
            <v-row wrap>
              <v-col cols="12" sm="12" md="12" lg="12" align="center">
                <v-img
                  v-if="rows.MemberImageFlag"
                  :src="rows.MemberImage"
                  :alt="rows.Fullname"
                  lazy-src="/no-image-available.png"
                  width="100px"
                  height="100px"
                >
                </v-img>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Membership Id</h6>
                <h6 class="text-dark">{{ rows.MembershipId }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Name</h6>
                <h6 class="text-dark">{{ rows.Fullname }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Designation</h6>
                <h6 class="text-dark">{{ rows.MemberDesignation }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Zone</h6>
                <h6 class="text-dark">{{ rows.ZoneName }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Lom</h6>
                <h6 class="text-dark">{{ rows.LomName }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member DOB</h6>
                <h6 class="text-dark">{{ rows.DOB }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Mobile No</h6>
                <v-text-field
                  v-model="MobileNo"
                  :rules="MobileNoRules"
                  :counter="10"
                  v-mask="'##########'"
                  type="number"
                  required
                  dense
                  outlined
                >
                </v-text-field>
                <!-- <h6 class="text-dark">{{ rows.MobileNo }}</h6> -->
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Email Id</h6>
                <v-text-field
                  v-model="EmailId"
                  placeholder="Enter Email Id"
                  :rules="EmailIdRules"
                  required
                  outlined
                  dense
                >
                </v-text-field>
                <!-- <h6 class="text-dark">{{ rows.EmailId }}</h6> -->
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3" v-if="rows.CityId == ''">
                <h6>Member City Name</h6>
                <v-text-field
                  v-model="CityName"
                  placeholder="Enter City Name"
                  :rules="CityNameRules"
                  required
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <h6>Member Address</h6>
                <h6 class="text-dark">{{ rows.AddressTxt }}</h6>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" md="6" lg="4">
                <h6><span class="text-danger">*</span> Transaction Type</h6>
                <v-select
                  :reduce="(option) => option.value"
                  :items="TransactionTypeOptions"
                  :rules="TransactionTypeRules"
                  v-model="TransactionType"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="4">
                <h6>
                  <span class="text-danger">*</span> Payment Id / Checque No. /
                  DD No.
                </h6>
                <v-text-field
                  v-model="PaymentId"
                  placeholder="Enter Payment Id"
                  :rules="PaymentIdRules"
                  required
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12" align="center">
              <v-btn
                v-if="ResultFlag"
                :disabled="!valid1"
                @click.prevent="confirmAlert"
                :loading="SubmitFlag"
                elevation="30"
                shaped
                tile
                medium
                color="success"
                class="font-size-h6 px-6 py-4 mt-6 white--text"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      search: "",
      uploadPercentage: 0,
      SubmitFlag: false,
      SearchFlag: false,
      ResultFlag: false,
      AddressFlag: false,

      OverlayLoadingFlag: false,

      MembershipIdRules: [(v) => !!v || "Membership Id is required"],
      MembershipId: "",

      PaymentIdRules: [(v) => !!v || "Payment Id is required"],
      PaymentId: "",

      PaidDate: "",
      PaidDateRules: [(v) => !!v || "Paid Date is required"],
      menu1: false,

      TransactionType: "",
      TransactionTypeRules: [(v) => !!v || "Transaction Type is required"],
      TransactionTypeOptions: [],
      TransactionTypeOptionsLoading: false,

      EmailId: "",
      EmailIdRules: [
        (v) => !!v || "Email Id is required",
        (v) => /.+@.+\..+/.test(v) || "Email Id must be valid",
      ],

      MobileNo: "",
      MobileNoRules: [(v) => !!v || "Mobile Number is required"],

      CityName: "",
      CityNameRules: [(v) => !!v || "City Name is required"],

      rows: {},

      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      selected1: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.pageData();
      }
    },
    MembershipId: function () {
      console.log("watch MembershipId called");
      // this.resetForm();
      this.rows = {};
      this.ResultFlag = false;
    },
    TransactionTypeOptions: function () {
      console.log("watch TransactionTypeOptions called");
      this.TransactionTypeOptionsLoading = false;
    },
    rows: function () {
      console.log("watch rows called");
      this.MobileNo = this.rows.MobileNo;
      this.EmailId = this.rows.EmailId;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "senior_members",
        Action: "create_2",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getTransactionTypeOptions();
    },
    resetForm() {
      console.log("resetForm called.");
      this.rows = {};
      this.PaidDate = "";
      this.TransactionType = "";
      this.PaymentId = "";
    },
    getTransactionTypeOptions() {
      console.log("getTransactionTypeOptions called");
      this.TransactionTypeOptionsLoading = true;
      var selectbox1_source = "TransactionType";
      var selectbox1_destination = "TransactionTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 3,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");
      var MembershipId = this.MembershipId;
      console.log({ MembershipId });
      if (MembershipId != "") {
        this.ResultFlag = false;
        this.OverlayLoadingFlag = true;
        this.Member = {};
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 8,
          MembershipId: this.MembershipId,
          PaidDate: this.PaidDate,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;
        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.rows = records;
              thisIns.ResultFlag = true;
            } else {
              thisIns.rows = {};
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        var message = "Membership Id is required";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");

      var validate1 = this.$refs.form1.validate();
      var paid_date = this.PaidDate;
      var flag = this.ResultFlag;

      console.log({ validate1 }, { paid_date }, { flag });

      if (validate1 && paid_date != "" && flag) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (!validate1 || paid_date == "") {
          var message = "Kindly fill the required fields";
          this.sweetAlert("error", message, false);
        }
        if (!flag) {
          var message =
            "Kindly search the membership id first and then submit.";
          this.sweetAlert("error", message, false);
        }
      }
    },
    submitForm() {
      console.log("submitForm called");

      var validate1 = this.$refs.form1.validate();
      var paid_date = this.PaidDate;
      var flag = this.ResultFlag;

      console.log({ validate1 }, { paid_date }, { flag });

      if (validate1 && paid_date != "" && flag) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/upgrade-sma-member";
        var upload = {
          UserInterface: 1,
          MembershipId: this.MembershipId,
          PaidDate: this.PaidDate,
          TransactionType: this.TransactionType,
          PaymentId: this.PaymentId,
          MobileNo: this.MobileNo,
          EmailId: this.EmailId,
          CityName: this.CityName == "" ? null : this.CityName,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.rows = {};
              thisIns.MembershipId = "";
              thisIns.ResultFlag = false;
              thisIns.sweetAlert("success", output, true);
              thisIns.resetForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        if (!validate1 || paid_date == "") {
          var message = "Kindly fill the required fields";
          this.sweetAlert("error", message, false);
        }
        if (!flag) {
          var message =
            "Kindly search the membership id first and then submit.";
          this.sweetAlert("error", message, false);
        }
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss"></style>
